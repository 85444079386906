import detect from "detect.js";
import React from "react";
import "./index.scss";

const ua = detect.parse(navigator.userAgent);

function hidePhone() { 
  if (ua.os !== null) {
    if (ua.os.family === 'iOS') {
      document.getElementById("iphone 7 & 8").style.display = 'block';
      document.getElementById("iphone 11").style.display = 'block';
      document.getElementById("btnIfIphone").style.display = 'block';
      document.getElementById("GooglePixel").style.display = 'none';
      document.getElementById("Samsung").style.display = 'none';
      document.getElementById("Huawei").style.display = 'none';
      document.getElementById("btnIfAndroid").style.display = 'none';
    }
    else {
      document.getElementById("GooglePixel").style.display = 'block';
      document.getElementById("Samsung").style.display = 'block';
      document.getElementById("Huawei").style.display = 'block';
      document.getElementById("btnIfAndroid").style.display = 'block';
      document.getElementById("iphone 7 & 8").style.display = 'none';
      document.getElementById("iphone 11").style.display = 'none';
      document.getElementById("btnIfIphone").style.display = 'none';
    }
  }

  // if Desktop 
  if (ua.device !== null) {
    if (ua.device.type === 'Desktop') {
      document.getElementById('btnIfIphone').style.display = 'none';
      document.getElementById('btnIfAndroid').style.display = 'none'
      document.getElementById("iphone 7 & 8").style.display = 'block';
      document.getElementById("iphone 11").style.display = 'block';
      document.getElementById("GooglePixel").style.display = 'block';
      document.getElementById("Samsung").style.display = 'block';
      document.getElementById("Huawei").style.display = 'block';
    }
  }
}
console.log( ua.device.family)
console.log( ua.device.type)

function showAndroidDiv() {
  document.getElementById("GooglePixel").style.display = 'block';
  document.getElementById("Samsung").style.display = 'block';
  document.getElementById("Huawei").style.display = 'block';
  document.getElementById('btnIfIphone').style.display = 'none';
  document.getElementById('btnIfAndroid').style.display = 'none';
};

function showIphoneDiv() {
  document.getElementById('hiddenDiv').style.display = 'block';
  document.getElementById('btnIfIphone').style.display = 'none';
  document.getElementById('btnIfAndroid').style.display = 'none';
};


function WhereIs() {
  return (
    <div className='WhereIs'>
      <h1>Where is my NFC reader?</h1>
        <section>
          <div className="box2" onLoad={hidePhone}> 
            <div className="phoneDiv2" id='iphone 7 & 8'>
              <img alt="iPhone 7 and 8 (app needed)" src="/images/phones/iPhone_8_White_Back.svg" />
              <h2>iPhone 7 and 8<br/>(app needed)</h2>
            </div>
            <div className="phoneDiv2" id='iphone 11'>
              <img alt="iPhone X, XR, XS, 11 & Onwards" src="/images/phones/iPhone_X_White_Back.svg" />
              <h2>iPhone X, XR, XS,<br/>11 & Onwards</h2>
            </div>
            <div id='btnIfIphone'>
              <button id='btnIphone' onClick={showAndroidDiv}>
              <div id='btnText'>
                <h2> Not using an iPhone?</h2>
              </div>
              </button>
            </div>
            <div className="phoneDiv2" id='GooglePixel'>
              <img alt="Google Pixel" src="/images/phones/Google_Pixel_4_White_Back.svg" />
              <h2>Google Pixel<br/>&nbsp;</h2>
            </div>
            <div className="phoneDiv2" id='Samsung'>
              <img alt="Samsung" src="/images/phones/Samsung_S10_White_Back.svg" />
              <h2>Samsung<br/>&nbsp;</h2>
            </div>
            <div className="phoneDiv2" id='Huawei'>
              <img alt="Huawei" src="/images/phones/Huawei_White_Back.svg" />
              <h2>Huawei<br/>&nbsp;</h2>
            </div>  
            <div id='btnIfAndroid'>
              <button id='btnAndroid' onClick={showIphoneDiv}>
              <div id='btnText'>
                <h2> Not using an Andoid?</h2>
              </div>
              </button>
            </div>
            <div id="hiddenDiv">
              <div>
               <img alt="iPhone X, XR, XS, 11 & Onwards" src="/images/phones/iPhone_X_White_Back.svg" />
                <h2>iPhone X, XR, XS,<br/>11 & Onwards</h2>
              </div>
              <div>
                <img alt="iPhone 7 and 8 (app needed)" src="/images/phones/iPhone_8_White_Back.svg" />
                <h2>iPhone 7 and 8<br/>(app needed)</h2>
              </div>
            </div>
        </div>
      </section>      
    </div>
  );
}
export default WhereIs;