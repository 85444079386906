import React from "react";
import "./index.scss";

function HowTo() {
  return (
    <div className="HowTo">
      <section>
        <div className="animation">
          <img alt="How to tap" src= "/images/how_to_tap.svg" />
        </div>
        <div className="content">
          <h1>How to tap</h1>
          <ul>
            <li><span><img src="/images/1.svg" alt="1" /></span><p>Unlock your NFC enabled smartphone</p></li>
            <li><span><img src="/images/2.svg" alt="2" /></span><p>Tap your smartphone against the product call to action</p></li>
            <li><span><img src="/images/3.svg" alt="3" /></span><p>Follow the notification to launch the experience in your browser</p></li>
          </ul>
        </div>
      </section>
    </div>
  );
}

export default HowTo;
