import React from 'react';

import './index.scss';

const ScrollChevron  = () => {
    return (
        <span
            className="scroll-chevron"
        >
            <img alt="" src="/images/arrow.svg" />
        </span>
    );
}
export default ScrollChevron;
