import React from "react";
import "./App.scss";

import "animate.css/animate.min.css" ;
import ScrollAnimation from "react-animate-on-scroll";

import Header from "./components/Header";
import WhatIs from "./components/WhatIs";
import CompatiblePhones from "./components/CompatiblePhones";
import HowTo from "./components/HowTo";
import WhereIs from "./components/WhereIs";
import Questions from "./components/Questions";
import Footer from "./components/Footer";

function App() {
  return (
    <>
      <div className="App">
        <Header />

        <ScrollAnimation
          animatePreScroll={false}
          animateIn="animate__fadeInUp"
          duration={1}
          initiallyVisible={false}
          animateOnce={true}
        >
          <WhatIs />
        </ScrollAnimation>

        <ScrollAnimation
          animatePreScroll={false}
          animateIn="animate__fadeInUp" 
          duration={1}
          initiallyVisible={false}
          animateOnce={true}
        >
          <CompatiblePhones />
        </ScrollAnimation>

        <ScrollAnimation
          animatePreScroll={false}
          animateIn="animate__fadeInUp" 
          duration={1}
          initiallyVisible={false}
          animateOnce={true}
        >
          <HowTo />
        </ScrollAnimation>

        <ScrollAnimation
          animatePreScroll={false}
          animateIn="animate__fadeInUp" 
          duration={1}
          initiallyVisible={false}
          animateOnce={true}
        >
          <WhereIs />
        </ScrollAnimation>

        <ScrollAnimation
          animatePreScroll={false}
          animateIn="animate__fadeInUp" 
          duration={1}
          initiallyVisible={false}
          animateOnce={true}
        >
          <Questions />
        </ScrollAnimation>
      </div>
      <Footer />
    </>
  );
}

export default App;
