import React from "react";
import "./index.scss";

function Questions() {
  return (
    <div className="Questions">
      <h1>More Questions Answered</h1>

      <h2>Can my phone case block the NFC tap?</h2>
      <p>Yes – Certain phone cases, particularly those with metal components. Try taking your phone case off. </p>

      <h2>Why is my phone not responding when I tap the NFC?</h2>
      <p>- Try adjusting the angle of your tap</p>
      <p>- Your device is not NFC compatible (See ‘About NFC’)</p>
      <p>- Your device is not powered and unlocked (On the home screen)</p>
      <p>- NFC is not active (Android only - search for ‘NFC’ in Settings)</p>
      <p>- An app is required to detect NFC tags. Search ‘SharpEnd NFC Scanner’ on the app store.</p>

      <h2>How long do I need to hold my phone near the product?</h2>
      <p>As soon as you tap the product the content is instantly triggered and launched on your smartphone.</p>

      <h2>Why does my phone respond intermittently to the enabled product?</h2>
      <p>- The position of the NFC reader on smartphones differs between manufacturers (See ‘Where is my NFC reader?’)</p>
      <p>- Ensure the reader of the smartphone taps the area where the NFC tag is placed.</p>

      <h2>Can my phone read NFC when it is powered off or locked?</h2>
      <p>NFC readers on smartphones only work when the device is powered and unlocked.</p>

      <h2>Does the NFC enabled product log and store any of my data?</h2>
      <p>No. Information is not logged and stored on the product.</p>

      <h2>Does the NFC enabled product track my location?</h2>
      <p>No. User location cannot be tracked via the product.</p>

      <h2>Can NFC damage my smartphone?</h2>
      <p>No. NFC cannot damage your smartphone since it is a passive chip that is being read by the device.</p>
    </div>
  );
}

export default Questions;