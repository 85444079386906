import React from "react";
import "./index.scss";

function CompatiblePhones() {
  return (
    <div className="Compatible">
      <h1>Compatible Phones</h1>
      <section>
        <div>
          <img alt="iPhone X, XR, XS, 11 &amp; 11 Pro" src="/images/phones/iPhone_X_White_Front.svg" />
          <div>
            <h2>iPhone 7, 8, X &amp; Onwards</h2>
            <p>NFC is automatically activated. Follow instructions below.</p>
          </div>
        </div>
        <div>
          <img alt="Major Android Devices" src="/images/phones/Google_Pixel_4_White_Front.svg" />
          <div>
            <h2>Major Android Devices</h2>
            <p>Check if available by searching for ‘NFC’ in Settings</p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CompatiblePhones;
