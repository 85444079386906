import React from "react";
import "./index.scss";

function Footer() {
  return (
    <div className="Footer">
      <div>
        <img src="/images/se_triangle.svg" alt="SharpEnd" />
        <h2><a href="https://wearesharpend.com/privacy-policy/">Privacy Policy</a></h2>
        <h2><a href="https://wearesharpend.com/terms-conditions/">Terms and Conditions</a></h2>
        <p>© 2023 Sharp End Partnership, Ltd. All rights reserved.</p>
      </div>
    </div>
  );
}

export default Footer;
