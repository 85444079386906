import React from "react";
import "./index.scss";

const Logo = () => {
  return (
    <div className="Logo">
      <img alt="logo" src="/images/iott_logo.svg" />
    </div>
  );
};
export default Logo;
