import React from "react";
import "./index.scss";

function WhatIs() {
  return (

    <div className="WhatIs">
      <section>
        <div>
          <h1>What is NFC?</h1>
          <p>NFC = Near Field Communication.</p>
          <br/>
          <p>It is an exciting new way to access digital content from products, and is the same technology used in contactless payments. The product where you found this link has an NFC tag inside it.</p>
          <br/>
          <p>By simply following the instructions below, you will be able to read NFC tags with your phone.</p>
        </div>
        <div className="placeholder">
          <img alt="What is NFC?" src= "/images/what_is_nfc.svg" />
        </div>
      </section>
    </div>
    
  );
}

export default WhatIs;
