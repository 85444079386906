import React from "react";
import "./index.scss";
import Logo from "../Logo";
import ScrollChevron from "./ScrollChevron";

function Header() {
  return (
    <>
    <Logo />
    <div className="Header">
      <section>
        <div>
          <img className="small" alt="Discover the possibilities of NFC" src="/images/header_small.svg" />
        </div>
        <div><h1>Discover the<br/>possibilities of NFC</h1></div>
      </section>
      <ScrollChevron />
    </div>
    </>
  );
}

export default Header;
